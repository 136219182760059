@import 'styles/global';

.dialog {
  padding: $spacing * 4;
  padding-bottom: 0;
  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .input {
    width: 100%;
  }
}

.actions {
  width: 100%;
}
