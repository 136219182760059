@import 'styles/global';

.actions {
  min-width: 350px;
}

.moreButton {
  width: 24px;
  height: 24px;
  .row:hover & {
    opacity: 1;
  }
  opacity: 0;
}

.username {
  &.clickable {
    cursor: pointer;
  }
  .pinned {
    color: $color-primary-40;
  }
}
