@import 'styles/global';

.dialog {
  padding: $spacing * 4;
  padding-bottom: 0;
  .title {
    text-align: center;
  }
  .input {
    width: 100%;
  }
  .searchResults {
    min-width: 500px;
    width: 100%;
    max-height: calc(100vh - 540px);
    overflow: auto;
  }

  .user {
    cursor: pointer;

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

.actions {
  width: 100%;
}

.menuItem {
  width: 100%;

  .mainText {
    color: $color-primary-10;
  }

  .subText {
    color: $color-neutral-variant-30;
  }
}
