@import 'styles/global.scss';

.root {
  width: 100%;
  .top {
    width: 100%;
    min-height: 160px;
    align-items: center;

    background-image: url(../../../public/images/influencer_matcher/background.png);
    background-repeat: no-repeat;
    background-size: cover;

    .content {
      margin: auto;
      padding: $spacing * 4 $spacing * 2;
      width: 100%;
      max-width: $lg;
      color: white;

      @media screen and (max-width: $lg) {
        padding: $spacing * 4 $spacing * 2;
      }
    }
  }

  .bottom {
    padding: $spacing * 4 $spacing * 2;
    .content {
      max-width: $lg;
      width: 100%;

      .tabs {
        width: 100%;
        .tab {
          max-width: unset;
          border-radius: $border-radius * 3.5;
        }
      }
    }
  }
}
