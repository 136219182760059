@import 'styles/global';

.root {
  height: 100%;
  .profile {
    box-sizing: border-box;
    width: 100%;
    color: white;

    .avatar {
      width: 96px;
      height: 96px;
    }

    .category {
      padding: $spacing * 0.75 $spacing * 1.5;
      border-radius: 8px;
      background-color: $color-primary-90;
      color: $color-secondary-10;
    }

    .basic {
      width: calc(100% - 128px);

      .name {
        word-break: break-all;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .info {
        color: $color-primary-90;
      }

      .bio {
        color: $color-primary-90;

        max-width: 500px;
        word-break: break-all;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .details {
      box-sizing: border-box;
      width: 100%;
      border-radius: 18px;
      padding: $spacing * 3;
      background-color: $color-surface;
      color: $color-primary-10;
    }

    .tips {
      color: $color-primary-80;
    }
  }

  .metrics {
    overflow: auto;
    height: 100%;
  }
}
