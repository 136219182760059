.bar {
  width: 100%;
  height: 100%;
}

.arrow {
  transition: color linear 0.2s, transform linear 0.2s;
  transform-origin: 50% 50%;
}

.progressText {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: color linear 0.2s;
}
