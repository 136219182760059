@import 'styles/global.scss';

.root {
  width: 100%;

  .container {
    border: 1px solid $color-neutral-variant-50;
    border-radius: $spacing * 3;
    padding: $spacing * 4;
    background-color: $color-surface;

    .message {
      cursor: pointer;
    }

    .edit {
      width: 60%;
      border-radius: $spacing * 3;
      padding: $spacing * 2;
      background-color: $color-surface-1;

      .preview {
        white-space: pre-wrap;
        max-height: 340px;
        overflow: auto;
      }

      .select {
        width: 100%;
      }

      .placeholder {
        border-radius: $spacing * 0.5;
        border: 1px solid $color-neutral-variant-50;
      }

      .addInfluencerBtn {
        min-width: 180px;
      }
    }

    .influencers {
      width: 50%;
      .viewMore {
        color: $color-primary-40;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.item {
  width: 100%;
}

.input {
  width: 100%;
}
