@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    .content {
      min-width: 500px;
      padding: $spacing * 3;
      overflow: hidden;
      text-align: center;

      .subtitle {
        max-width: 440px;
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
