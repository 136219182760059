@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .content {
    width: 480px;
    overflow: hidden;
    padding: $spacing * 2 $spacing * 4;
  }
  .actions {
    padding: $spacing * 3;
  }
}
