@import 'styles/global';

$backdrop-color: rgba(0, 0, 0, 0.5);

.root {
  position: fixed;
  z-index: 1500;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  .center {
    position: absolute;
    pointer-events: none;
    overflow: hidden;

    .block {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0px 2000px;
      border-radius: 8px;
      pointer-events: none;
    }
  }

  .up {
    position: absolute;
    top: 0;
    pointer-events: all;
    background-color: $backdrop-color;
  }

  .right {
    position: absolute;
    right: 0;
    pointer-events: all;
    background-color: $backdrop-color;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    pointer-events: all;
    background-color: $backdrop-color;
  }

  .left {
    position: absolute;
    left: 0;
    pointer-events: all;
    background-color: $backdrop-color;
  }
}
