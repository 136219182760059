@import 'styles/global';

$icon-width: 16px;
$icon-height: 16px;

.root {
  flex-direction: row !important;
  gap: $spacing * 2;
  overflow: auto;

  @media screen and (max-width: $md) {
    flex-direction: column !important;
  }

  .grid {
    width: 100%;
    min-width: 300px;

    .subscription {
      position: relative;
      height: 100%;
      background: #f6f9ffe6;
      border-radius: 28px;
      padding: $spacing * 4 $spacing * 3;
      border: 5px solid #f6f9ff;

      &:hover {
        border: 5px solid $color-primary-40;
        background-color: #f6f9ffff;
      }

      .price {
        color: #1976d2;
      }

      .duration {
        color: #0a2c65;
      }

      .benefits {
        color: $color-primary-10;
        .icon {
          width: $icon-width;
          height: $icon-height;

          &.check {
            color: #3ed4ae;
          }
        }

        .benefit {
          word-break: break-word;
        }
      }

      .popular {
        position: absolute;
        top: calc(-5px - $spacing * 1.5);
        right: $spacing * 3;
        border-radius: 0 0 $spacing $spacing;
        padding: $spacing;
        background-color: $color-primary-40;
        color: white;
      }
    }
  }
}

.root::-webkit-scrollbar {
  display: none;
}
