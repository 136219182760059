@import 'styles/global';

.root {
  width: $user-sidebar-width;

  .container {
    color: $color-neutral-variant-30;
    padding: $spacing * 2;
    height: 100%;

    .btnBack {
      padding: $spacing * 2 0;
    }

    .content {
      flex: 1 1;

      .title {
        padding: 0 0;
        color: $color-primary-10;
      }

      .link {
        width: calc(100% - $spacing * 0.5);
        box-shadow: none;
        text-align: center;
        padding: 0 $spacing * 2; // act like button
        height: 56px;
        border-radius: 8px;
        transition: background-color 250ms; // act like button
        cursor: pointer;
        color: $color-neutral-variant-30;
        &.selected {
          color: $color-primary-40;
          background-color: $color-surface-5;
        }

        &:hover {
          color: $color-primary-40;
          background-color: $color-surface-3;
        }

        .label {
          word-break: break-word;
        }
      }

      .support {
        padding: $spacing $spacing * 2;
      }
    }
  }
}
