@import 'styles/global';

.root {
  width: 100%;
  padding: $spacing * 8;
  .container {
    margin: auto;
    width: 100%;
    min-width: 300px;
    max-width: 600px;
    background-color: $color-surface-1;
    border-radius: $spacing * 2.5;
    padding: $spacing * 6 $spacing * 3;

    .content {
      width: 100%;

      .text {
        word-break: break-all;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .item {
        .title {
          min-width: 150px;
        }

        .wrapper {
          width: calc(100% - 150px);
        }
      }
    }
  }
}
