@import 'styles/global.scss';

.root {
  width: 100%;

  .mode {
    > button {
      box-shadow: none !important;
      padding-left: $spacing * 2.5 !important;
      padding-right: $spacing * 2.5 !important;
    }
    .left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0px;
      &.selected {
        border-right-width: 1px;
      }
    }
    .right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left-width: 0px;
      &.selected {
        border-left-width: 1px;
      }
    }
  }

  .filters {
    flex-wrap: wrap;
    gap: $spacing;

    max-height: 136px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .filter {
      color: $color-neutral-variant-30;
      padding: 0 $spacing * 2;
    }

    .clear {
      padding: 0 $spacing * 2;
    }
  }

  .filters::-webkit-scrollbar {
    display: none;
  }

  .table {
    min-height: 300px;

    .empty {
      max-width: 500px;
      text-align: center;

      .img {
        width: 120px;
      }
    }
  }
}
