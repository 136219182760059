@import 'styles/global';

.root {
  color: $color-primary-10;
  .img {
    border-radius: 50%;
    max-width: 48px;
  }
  .verified {
    color: #1d9bf0;
    &.business {
      color: #f2d632;
    }
    &.government {
      color: $color-neutral-variant-30;
    }
  }
  .text {
    max-width: 120px;

    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
