@import 'styles/global';

.root {
  .breadcrumbs {
    .link {
      opacity: 0.38;
      cursor: pointer;
    }
  }
  .filter {
    color: $color-neutral-variant-70;
    &.selected {
      color: $color-primary-40;
    }
  }
  .category {
    padding: $spacing * 4;
    border-radius: $spacing * 2;
    border: 1px solid $color-neutral-variant-70;
  }
}
