@import 'styles/global.scss';

.root {
  width: 100%;

  .tableHeader {
    padding: $spacing * 3 $spacing * 3;
    background-color: $color-surface-1;
  }
}

.actions {
  position: absolute;
  right: 0px;
  padding: 0px $spacing * 2;
  padding-left: 20px;

  .background {
    left: 0px;
    position: absolute;
    width: 100%;
    border-radius: 40px 0px 0px 40px;
    height: 80px;
    transform: translateY(-20px);

    background-color: $color-surface-2;
    filter: blur(2px);
  }

  .btn {
    background-color: white;
  }

  .row:hover & {
    opacity: 1;
  }
  opacity: 0;
}

.chips {
  height: 40px;
}
