@import 'styles/global';

.root {
  width: 100%;
  max-width: $lg;
}

.pointer {
  cursor: pointer;
}

.dialog {
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
}
