@import 'styles/global';

.root {
  .breadcrumbs {
    .link {
      opacity: 0.38;
      cursor: pointer;
    }
  }
  .filter {
    color: $color-neutral-variant-70;
    &.selected {
      color: $color-primary-40;
    }
  }
  .category {
    padding: $spacing * 4;
    border-radius: $spacing * 2;
    border: 1px solid $color-neutral-variant-70;

    .name {
      &.link {
        color: $color-primary-40;
        cursor: pointer;
      }
    }

    .highlight {
      color: $color-primary-40;
    }

    .usernames {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
