@import 'styles/global';

$step-chip-size: 64px;
$step-next-height: 40px;

.root {
  width: 100%;

  .container {
    width: $panel-width;
    padding: $spacing * 3;

    &.top {
      border-top-left-radius: $spacing * 3.5;
      border-top-right-radius: $spacing * 3.5;
      background-color: $color-surface-4;
    }

    &.bottom {
      border-bottom-left-radius: $spacing * 3.5;
      border-bottom-right-radius: $spacing * 3.5;
      background-color: $color-surface-1;
    }

    .step {
      width: $step-chip-size;
      height: $step-chip-size;
      border-radius: $step-chip-size * 0.5;
    }

    .title {
      flex: 1;
    }

    .input {
      width: 100%;
    }

    .select {
      width: 100%;
    }
  }
}

.empty {
  padding: $spacing * 3;
  text-align: center;
  .link {
    text-decoration: underline;
    cursor: pointer;
    color: $color-primary-40;
  }
}
