@import 'styles/global';

.root {
  .title {
    padding: $spacing * 2;
  }
  .content {
    max-width: 500px;
    text-align: center;

    .title {
      padding: $spacing * 2;

      .info {
        padding: $spacing * 2;
      }
    }

    .items {
      padding: 0 $spacing * 3;
      width: 100%;
      .item {
        width: 100%;
        &.selected {
          background-color: $color-neutral-80;
        }
        .icon {
          font-weight: bold;
          color: $color-primary-10;
        }
        .text {
          text-align: left;
          flex: 1 1;
        }
      }
    }
  }
  .actions {
    padding: $spacing * 3;
  }
}
