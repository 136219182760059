@import 'styles/global';

$logo-height: 56px;
$logo-height-mobile: 48px;

.root {
  width: 100%;
  margin-left: 0px;
  transition: margin-left 0.2s ease !important;
  height: $appbar-height;
  color: $color-neutral-100;
  background-color: $color-neutral-10;

  .logo {
    height: $logo-height;

    @media screen and (max-width: $sm) {
      height: $logo-height-mobile;
    }

    margin-left: $spacing * 2;
  }

  .center {
    flex: 1;
    display: none; // FIXME: remove this line to enable search bar

    .search {
      margin: auto;
      color: $color-neutral-variant-30;
      width: 550px;
      border-radius: $border-radius * 3.5;
      background-color: $color-surface-3;
    }
  }

  .credits {
    cursor: pointer;
    color: #64b5f6;
    .icon {
      color: #64b5f6;
    }
  }

  .subscription {
    border: 1px solid #7c91ab;
    border-radius: 24px;
    .plan {
      padding: $spacing * 1.25 $spacing * 3;
      color: #64b5f6;
    }
    .upgrade {
      border-radius: 24px;
      padding: $spacing * 1.25 $spacing * 3;
      cursor: pointer;
      background-color: #64b5f6;
      color: $color-primary-20;
    }
  }
}
