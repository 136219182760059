@import 'styles/global';

.root {
  padding: $spacing * 2 $spacing * 4;
  max-width: 900px;

  .btnClose {
    position: fixed;
    right: 0;
    top: 0;
  }

  .img {
    width: 200px;
    height: 200px;
    margin: auto;
    margin-top: $spacing * 6;
  }

  .category {
    margin: $spacing;
    padding: $spacing * 0.5 $spacing;
    border-radius: $spacing;
    border: 1px solid $color-neutral-variant-30;

    .btnDelete {
      margin: auto;
      margin-left: $spacing;
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

    &.clickable {
      cursor: pointer;
    }

    &.selected {
      color: #1c1b1f;
      background-color: #add0e5;

      &:hover {
        background-color: #99b9cc;
      }
    }

    &.disabled {
      color: $color-error-40;
      border-color: $color-error-40;

      &:hover {
        color: white;
        background-color: $color-error-40;
      }
    }

    &:hover {
      color: #1c1b1f;
      background-color: #99b9cc;
    }
  }

  .pool {
    padding: $spacing * 4;
    border-radius: $spacing * 2;
    background-color: $color-neutral-90;
  }
}
