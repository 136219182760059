@import 'styles/global.scss';

.root {
  background-color: #a2ffe5;
  padding: $spacing * 2 $spacing * 4;

  @media screen and (max-width: $md) {
    padding: $spacing * 2 $spacing * 0.5;
  }

  .news {
    flex: 1 1;
    flex-direction: row;
    gap: $spacing * 4;

    @media screen and (max-width: $md) {
      flex-direction: column;
      gap: 0;
    }

    .textContainer {
      align-items: center;

      @media screen and (max-width: $md) {
        align-items: flex-start;
      }

      .sale {
        padding: $spacing * 0.5 $spacing;
        border-radius: $spacing * 0.5;
        background: #00ffb9;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        .text {
          font-weight: 700;
        }

        @media screen and (max-width: $md) {
          display: none;
        }
      }

      .icon {
        color: #070b3c;
      }

      .highlight {
        font-weight: 700;
      }

      .linkInside {
        cursor: pointer;
        text-decoration: underline;
        display: none;
        margin-left: $spacing;

        @media screen and (max-width: $md) {
          display: unset;
        }
      }
    }

    .link {
      cursor: pointer;
      text-decoration: underline;

      @media screen and (max-width: $md) {
        display: none;
      }
    }
  }

  .close {
    cursor: pointer;
  }
}
