@import 'styles/global';

.root {
  padding: 0 $spacing * 4;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  .left {
    position: sticky;
    top: 0;
    left: 0;
  }

  .right {
    width: calc(100% - 380px - 64px);

    .link {
      cursor: pointer;
    }

    .conditions {
      border-radius: $spacing * 3;
      padding: $spacing * 4;
      background-color: $color-neutral-90;

      .category {
        margin: $spacing;
        padding: $spacing * 0.5 $spacing;
        border-radius: $spacing;
        border: 1px solid $color-neutral-variant-50;

        &.disabled {
          color: $color-error-40;
          border-color: $color-error-40;
        }
      }

      .filters {
        border-radius: $spacing * 3;
        border: 1px solid $color-neutral-variant-50;
        padding: $spacing * 4;

        .divider {
          padding: $spacing * 4 0;
        }
      }
    }
  }
}
