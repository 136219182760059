@import 'styles/global';

.dialog {
  padding: 0 $spacing * 4;

  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .container {
    border-radius: $spacing;
    border: 1px solid $color-secondary-40;
    background-color: $color-surface-2;
    padding: $spacing * 3;
    width: 100%;

    .chips {
      max-height: 170px;
      flex-wrap: wrap;
      gap: $spacing;
      overflow: auto;

      .chip {
        padding: $spacing $spacing * 1.5 $spacing $spacing * 2;
        height: 40px;
        border-radius: 20px;
        border: 1px solid $color-secondary-40;
        color: $color-neutral-variant-30;

        .cancel {
          cursor: pointer;
        }

        &.notExist {
          opacity: 0.38;
        }
      }
    }

    .success {
      color: $color-success-40;
    }

    .failed {
      color: $color-error-40;
    }

    .link {
      color: $color-primary-40;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.actions {
  width: 100%;
}
