@import 'styles/global';

.closeBtn {
  position: absolute;
  top: $spacing;
  right: $spacing;
}

.root {
  width: 100%;
  padding: $spacing * 6;

  @media screen and (max-width: $sm) {
    padding: $spacing * 6 $spacing * 2;
  }

  .top {
    flex-direction: row;
    gap: $spacing * 3;

    @media screen and (max-width: $sm) {
      flex-direction: column;
    }

    .title {
      max-width: 350px;

      @media screen and (max-width: $sm) {
        text-align: center;
      }
    }

    .info {
      align-items: flex-end;

      @media screen and (max-width: $sm) {
        align-items: center;
      }

      .tips {
        padding: $spacing;
        border-radius: $spacing * 0.5;
        background-color: #82f9cb;
        color: #008f4d;
      }

      .btn {
        width: 180px;
        cursor: pointer;
        text-align: center;
        border-bottom: 1px solid #dfe5ec;

        @media screen and (max-width: $sm) {
          width: 150px;
        }

        .btnText {
          padding: $spacing * 1.5 0;

          &.selected {
            color: $color-primary-40;
            border-bottom: 3px solid $color-primary-40;
          }
        }
      }
    }
  }
}
