@import 'styles/global';

.dialog {
  padding: 0 $spacing * 4;
  .title {
    text-align: center;
  }
  .input {
    width: 100%;
  }
  .searchResults {
    min-width: 500px;
    width: 100%;
    max-height: calc(100vh - 490px);
    overflow: auto;

    .addBtn {
      min-width: 110px;
    }

    &.smallSize {
      max-height: calc(100vh - 550px);
    }
  }
  .hint {
    width: 100%;
    color: $color-secondary-40;
  }
  .btns {
    width: 100%;
  }
}

.actions {
  width: 100%;
}
