@import 'styles/global';

.chips {
  height: 56px;
  align-items: center;
}

.profile {
  color: $color-primary-10;
}

.text {
  white-space: nowrap;
}

.tableHeader {
  padding: $spacing * 3 $spacing * 3;
  background-color: $color-surface-1;

  .sortOrder {
    cursor: pointer;
    color: $color-primary-40;
  }
}

.tweets {
  min-width: 165px;
  cursor: pointer;
  .info {
    color: $color-neutral-30;
  }

  .link {
    color: $color-primary-40;
  }
}

.actionBtn {
  visibility: hidden;
  color: $color-primary-40;
  cursor: pointer;

  .row:hover & {
    visibility: visible;
  }
}

.addedIcon {
  color: $color-primary-40;
}

.blur {
  width: 100%;
  backdrop-filter: blur(6px);
  height: calc((100% - 92px) * 0.3);
  pointer-events: auto;
  padding: $spacing;

  .text {
    max-width: 400px;
    word-break: break-all;
    white-space: wrap;
    text-align: center;
  }
}
