@import 'styles/global';

.tips {
  position: relative;
  background-color: white;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3), 0px 8px 12px 6px rgba(0, 0, 0, 0.15);

  &.bottom {
    &.left {
      &::before {
        position: absolute;
        transform: rotate(45deg);
        content: '';
        width: 16px;
        height: 16px;
        top: -8px;
        left: 32px;
        background-color: white;
      }
    }

    &.right {
      &::before {
        position: absolute;
        transform: rotate(45deg);
        content: '';
        width: 16px;
        height: 16px;
        top: -8px;
        right: 32px;
        background-color: white;
      }
    }
  }

  &.top {
    &.left {
      &::before {
        position: absolute;
        transform: rotate(45deg);
        content: '';
        width: 16px;
        height: 16px;
        bottom: -8px;
        left: 32px;
        background-color: white;
      }
    }

    &.right {
      &::before {
        position: absolute;
        transform: rotate(45deg);
        content: '';
        width: 16px;
        height: 16px;
        bottom: -8px;
        right: 32px;
        background-color: white;
      }
    }
  }

  .content {
    padding: $spacing * 2;
    .title {
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
      letter-spacing: 0.5px;
    }
    .text {
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
    }
  }

  .action {
    padding: $spacing * 2;

    .skip {
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.4px;
      color: #add0e5;
      cursor: pointer;
    }
  }
}
