@import 'styles/global';

$chip-size: 64px;

.root {
  .container {
    width: 100%;
    padding: $spacing * 3;

    &:first-child {
      border-top-left-radius: $border-radius * 3.5;
      border-top-right-radius: $border-radius * 3.5;
      padding-top: $spacing * 5;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius * 3.5;
      border-bottom-right-radius: $border-radius * 3.5;
      padding-bottom: $spacing * 5;
    }

    .chip {
      color: $color-primary-40;
      background-color: $color-primary-90;
      border-radius: $chip-size * 0.5;
      width: $chip-size;
      min-width: $chip-size;
      height: $chip-size;

      .icon {
        margin: auto;
      }
    }

    .content {
      flex: 1 1;
      .operand {
        color: $color-neutral-variant-30;
      }
    }
  }

  .logicalOperator {
    width: 100%;
  }
}
