@import 'styles/global';

.moreButton {
  width: 24px;
  height: 24px;
  .row:hover & {
    opacity: 1;
  }
  opacity: 0;
}

.pointer {
  cursor: pointer;
}

.tableHeader {
  padding: $spacing * 3 $spacing * 6;
  align-items: flex-end;
  background-color: $color-surface-1;
}
