@import 'styles/global';

.moreButton {
  width: 24px;
  height: 24px;
  .row:hover & {
    opacity: 1;
  }
  opacity: 0;
}

.name {
  cursor: pointer;
  .pinned {
    color: $color-primary-40;
  }
}
