@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    .content {
      min-width: 500px;
      padding: $spacing * 3;
      overflow: hidden;

      .category {
        margin: $spacing;
        padding: $spacing * 0.5 $spacing;
        border-radius: $spacing;
        border: 1px solid $color-neutral-variant-30;
        cursor: pointer;

        &.selected {
          color: #1c1b1f;
          background-color: #add0e5;

          &:hover {
            background-color: #99b9cc;
          }
        }

        &:hover {
          color: #1c1b1f;
          background-color: #99b9cc;
        }
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
