@import 'styles/global';

.root {
  width: 520px;
  .title {
    padding: $spacing * 2;
  }
  .content {
    text-align: center;
    padding: $spacing * 2;

    .result {
      flex-wrap: wrap;
      gap: $spacing;
      justify-content: center;
      .link {
        cursor: pointer;
        padding: $spacing * 0.5 $spacing;
        border-radius: $border-radius;
        border: $border;

        &.error {
          color: $color-error-40;
          border-color: $color-error-40;
        }

        &:hover {
          background-color: #add0e5;
        }
      }
    }
  }
  .actions {
    padding: $spacing * 3;
  }
}
