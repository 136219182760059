@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    .content {
      max-width: 500px;
      padding: $spacing * 3;
      padding-bottom: 0;
      overflow: hidden;

      .description {
        flex: 1 1;
      }

      .template {
        width: 120px;
      }

      .upload {
        width: 100%;
        position: relative;

        .text {
          z-index: 1;
          padding: $spacing;
        }

        .progress {
          position: absolute;
          background-color: $color-primary-90;
          height: 100%;
          border-radius: $spacing * 2;
        }
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
