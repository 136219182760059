@import 'styles/global';

.root {
  width: 100%;
  min-height: calc(100vh - $appbar-height);
  .top {
    width: 100%;
    min-height: 160px;
    align-items: center;
    padding: 0 $spacing * 2;

    background-image: url(../../public/images/root/background.png);
    background-repeat: no-repeat;
    background-size: cover;

    .content {
      margin: auto;
      padding: $spacing * 4 0;
      width: 100%;
      max-width: $lg;
      color: white;

      @media screen and (max-width: $lg) {
        padding: $spacing * 4 $spacing * 2;
      }
    }
  }

  .bottom {
    padding: $spacing * 6;
    .container {
      width: 100%;
      max-width: $lg;
      margin: auto;
      .card {
        max-width: 360px;

        &:hover {
          box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.3);
        }

        .content {
          min-height: 100px;
        }

        .actions {
          padding: 0px $spacing * 2 !important;
        }

        .media {
          padding: $spacing * 2;
          margin-top: $spacing * 2;

          .img {
            height: 200px;
          }
        }
      }
    }
  }

  .changelog {
    padding: $spacing * 6;
    padding-top: $spacing * 2;
    .container {
      width: 100%;
      max-width: $lg;
      margin: auto;
      border-radius: 12px;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      padding: $spacing * 2;
      .details {
        max-width: 500px;
        .spinner {
          min-width: 100px;
          height: 100%;
        }
        .items {
          max-height: 144px;
          overflow-y: auto;

          .item {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }

  .white {
    flex: 1 1;
  }
}
