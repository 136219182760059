@import 'styles/global';

.root {
  width: $sidebar-width;

  .container {
    padding-top: $spacing * 4;
    .link {
      width: calc(100% - $spacing * 0.5);
      color: $color-neutral-variant-30;
      box-shadow: none;
      text-align: center;
      padding: 6px 8px; // act like button
      height: 80px;
      border-radius: 8px;
      transition: background-color 250ms; // act like button
      &.selected {
        color: $color-primary-40;
        background-color: $color-surface-4;
      }

      &:hover {
        background-color: $color-surface-4;
      }

      .label {
        word-break: break-word;
      }
    }
  }
}
