@import 'styles/global';

.root {
  width: 100%;
  padding: $spacing * 8;

  .subscription {
    width: 100%;
    min-width: 300px;
    max-width: 600px;
    background-color: $color-surface-1;
    border-radius: $spacing * 2.5;
    padding: $spacing * 3 $spacing * 2;

    .icon {
      width: 12px;
      height: 12px;
    }

    .invalid {
      color: $color-error-40;
    }

    .valid {
      color: $color-primary-40;
    }
  }

  .creditList {
    max-width: 1200px;
  }
  .creditTransactionList {
    max-width: 800px;
  }
}
