@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    width: 520px;
    .content {
      padding: $spacing * 3;
      padding-bottom: 0;
      overflow: hidden;
      align-items: center;

      .subtitle {
        color: $color-primary-10;
      }

      .input {
        width: 460px;

        .description {
          color: $color-primary-10;
        }

        .chips {
          max-height: 170px;
          flex-wrap: wrap;
          gap: $spacing;
          overflow: auto;

          .chip {
            padding: $spacing $spacing * 1.5 $spacing $spacing * 2;
            height: 40px;
            border-radius: 20px;
            border: 1px solid $color-secondary-40;
            color: $color-neutral-variant-30;

            .cancel {
              cursor: pointer;
            }

            &.notExist {
              opacity: 0.38;
            }
          }
        }
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
