@import 'styles/global.scss';

$img-container-size: 660px;

.root {
  width: 100%;
  height: 100vh;

  .imgContainer {
    width: 50%;
    min-width: $img-container-size;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #96be8c;

    .img {
      max-height: 100%;
      max-width: 100%;
      border-radius: 50%;
      padding: 15%;
    }
  }

  .block {
    padding: $spacing * 2;
    flex: 1 1;
    overflow: auto;

    .container {
      margin: auto;
      max-width: 420px;

      .telegram {
        color: $color-primary-40;

        .link {
          cursor: pointer;
        }
      }

      .note {
        text-align: center;
      }
    }
  }
}
