@import 'styles/global';

.root {
  width: 100%;
  padding: $spacing * 8;
  .container {
    width: 100%;
    min-width: 300px;
    max-width: 600px;
    background-color: $color-surface-1;
    border-radius: $spacing * 2.5;
    padding: $spacing * 3 $spacing * 2;
  }
}
