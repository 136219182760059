@import 'styles/global.scss';

.root {
  width: 100%;

  .filterBtn {
    box-shadow: none !important;
    padding-left: $spacing * 2.5 !important;
    padding-right: $spacing * 2.5 !important;
  }

  .table {
    min-height: 300px;

    .empty {
      max-width: 500px;
      text-align: center;

      .img {
        width: 120px;
      }
    }
  }
}
