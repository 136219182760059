@import 'styles/global';

.tableHeader {
  padding: $spacing * 3 $spacing * 3;
  background-color: $color-surface-1;
}

.profile {
  max-width: 260px;

  .link {
    cursor: pointer;
  }
}

.select {
  padding-left: $spacing * 1.5 !important;
  padding-right: $spacing * 1.5 !important;

  border-radius: 20px !important;
  height: 40px;
}

.actionBtn {
  min-width: 300px;

  &.showPostLinks {
    min-width: unset;
  }
}

.changeStage {
  width: 160px;
  height: 40px;
  border-radius: 20px;
}

.msgBtn {
  padding-left: $spacing * 2.5 !important;
  padding-right: $spacing * 2.5 !important;
}

.postLinksColumn {
  min-width: 40vw;

  .postLinks {
    max-width: calc(100% - 120px);
    overflow: auto;
  }
}
