@import 'styles/global.scss';

.root {
  min-height: 300px;

  .container {
    padding: $spacing * 4;
    border-radius: $spacing * 3;
    border: 1px solid $color-neutral-variant-50;
    background-color: $color-surface;

    .messages {
      padding: $spacing * 3;
      border-radius: $spacing * 3;
      background-color: $color-surface-1;

      .content {
        white-space: pre-wrap;
        max-height: 240px;
        overflow: auto;
      }

      .viewMore {
        color: $color-primary-40;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
