@import 'styles/global';

.dialog {
  padding: $spacing * 4;
  padding-bottom: 0;
  .title {
    text-align: center;

    .highlight {
      color: $color-primary-40;
    }
  }
  .input {
    width: 100%;
  }
}

.item {
  width: 100%;

  .highlight {
    color: $color-primary-40;
    &.error {
      color: $color-error-40;
    }
  }
}
