@import 'styles/global';

.root {
  width: 520px;
  .content {
    text-align: center;

    .title {
      padding: $spacing * 2;

      .subtitleText {
        color: $color-neutral-variant-30;
      }
    }

    .select {
      width: 100%;
      .value {
        text-align: left;
        color: $color-neutral-10;
        .text {
          word-break: break-all;
          white-space: wrap;
        }
        .subText {
          color: $color-neutral-variant-30;
          text-align: left;
        }
        .icon {
          color: $color-neutral-variant-30;
        }
        word-break: break-all;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .adGroups {
    margin-left: $spacing * 2;
    margin-right: $spacing * 2;
  }

  .actions {
    padding: $spacing * 3;
  }
}

.spinner {
  width: 300px;
  height: 300px;
}

.accountOption {
  width: 100%;
  color: $color-neutral-10;
  .text {
    word-break: break-all;
    white-space: wrap;
  }
  .subText {
    color: $color-neutral-variant-30;
    text-align: left;
  }
  .icon {
    color: $color-neutral-variant-30;
  }
}

.adGroupOption {
  width: 100%;
  color: $color-neutral-10;
  padding: $spacing 0;
  .left {
    flex: 1 1;

    .text {
      word-break: break-all;
      white-space: wrap;
    }

    .subText {
      color: $color-neutral-variant-30;
    }
  }
  .right {
    color: $color-neutral-variant-30;
    max-width: 100px;
    word-break: break-all;
    white-space: wrap;
  }
}
