@import 'styles/global';

.root {
  max-width: 900px;
  border-radius: $spacing * 2.5;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  .title {
    border-radius: $spacing * 2.5 $spacing * 2.5 0 0;
    padding: $spacing * 3;
    background-color: $color-surface-4;

    .chip {
      border-radius: $spacing;
      border: 1px solid $color-neutral-variant-50;
      padding: 6px $spacing * 2;

      .text {
        max-width: 180px;
        word-break: break-all;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .option {
      cursor: pointer;
      border: 1px solid $color-neutral-variant-50;
      border-left: none;
      padding: $spacing * 1 $spacing * 3;

      &.selected {
        background-color: #add0e5;
      }

      &.first {
        border-left: 1px solid $color-neutral-variant-50;
        border-radius: 100px 0px 0px 100px;
      }

      &.last {
        border-radius: 0px 100px 100px 0px;
      }
    }
  }
  .chart {
    width: 700px;
    height: 400px;
    padding: $spacing * 3;

    .refresh {
      cursor: pointer;
    }
  }
}
