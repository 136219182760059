@import 'styles/global.scss';

.root {
  width: 100%;
  margin-bottom: $spacing * 8;

  .top {
    width: 100%;
    min-height: 300px;
    align-items: center;

    background-image: url(../../../public/images/influencer_matcher/public-background-desktop.png);
    background-repeat: no-repeat;
    background-size: cover;

    .content {
      margin: auto;
      padding: $spacing * 4 $spacing * 2;
      width: 100%;
      max-width: $lg;
      color: white;

      @media screen and (max-width: $lg) {
        padding: $spacing * 4 $spacing * 2;
      }
    }
  }
  .bottom {
    width: 100%;
    max-width: $lg;
    padding: 0 $spacing * 2;
  }
}
