@import 'styles/global.scss';

.root {
  width: 100%;
  background-color: #e7f2fc;
  border-radius: 36px;
  height: 72px;

  .select {
    height: 100%;
    padding-left: $spacing * 1.5;
    border-radius: 36px 0px 0px 36px;
  }

  .input {
    width: 100%;
    height: 100%;
    border-radius: 0px 36px 36px 0px;

    .searchIcon {
      padding: 0px $spacing * 2 0px $spacing;
    }

    .searchBtn {
      padding: $spacing $spacing * 3;
    }
  }
}

.mobileRoot {
  width: 100%;
  background-color: #e7f2fc;
  border-radius: 36px;
  height: 72px;

  .input {
    width: 100%;
    height: 100%;
    border-radius: 36px;

    .searchIcon {
      padding: 0px $spacing * 2 0px $spacing;
    }

    .searchBtn {
      padding: $spacing $spacing * 3;
    }
  }
}

.tweets {
  &.hide {
    display: none;
  }
  .text {
    color: $color-primary-10;
  }
  .button {
    color: $color-primary-40;
    border-radius: 36px;
    height: 40px;
    padding: 0px 4px 0px 8px;
  }
}
