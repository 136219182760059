@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .content {
    min-width: 500px;
    padding: $spacing * 3;
    overflow: hidden;
  }
  .actions {
    padding: $spacing * 3;
  }
}

.spinner {
  width: 300px;
  height: 300px;
}
