@import 'styles/global';

.root {
  width: 100%;
  padding: $spacing * 8;

  .link {
    cursor: pointer;
  }

  .account {
    height: 40px;
    border-radius: 20px;
    border: 1px solid $color-neutral-variant-50;
    padding: $spacing * 1.5;
    color: $color-neutral-variant-50;

    &.connected {
      background-color: $color-primary-90;
      color: $color-primary-10;
    }

    .icon {
      border-radius: 50%;
      border: 1px solid $color-neutral-variant-50;
      width: 24px;
      height: 24px;

      &.connected {
        background-color: white;
      }
    }
  }

  .init {
    padding: $spacing * 6;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;

    .btn {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.dialog {
  max-width: 500px;
}
