@import 'styles/global';

.root {
  .content {
    text-align: center;
    padding: $spacing * 2;

    .info {
      padding: $spacing * 2;
      max-width: 500px;
    }
  }
  .actions {
    padding: $spacing * 3;
  }
}
