@import 'styles/global';

$chip-size: 48px;
$tab-size: 250px;

.root {
  width: 100%;

  .blackArea {
    min-width: $lg;
    padding: $spacing * 2;
    border-radius: 0px;

    .breadcrumbs {
      margin: $spacing * 2;

      .link {
        cursor: pointer;
      }
    }

    .container {
      padding: $spacing * 2;

      .content {
        margin: auto;

        .info {
          width: 640px;
          .name {
            width: 100%;
          }
          .box {
            padding: $spacing * 3;
            border-radius: $border-radius * 2;
            background-color: white;
            color: $color-secondary-40;

            &.disabled {
              opacity: 0.3;
            }

            .chip {
              color: $color-primary-40;
              background-color: $color-primary-90;
              border-radius: $chip-size * 0.5;
              width: $chip-size;
              height: $chip-size;

              .icon {
                margin: auto;
              }
            }

            .count {
              color: $color-primary-40;
            }
          }
        }
        .actionBtn {
          background-color: $color-secondary-20;
          border-radius: $spacing;
          .icon {
            color: $color-primary-80;
          }
        }

        .chart {
          &.disabled {
            opacity: 0.3;
          }
          .container {
            margin: auto;
            max-width: 300px;
            text-align: center;

            .updateBtn {
              transition: color 0ms;
            }
          }
        }
      }
    }
  }

  .detail {
    min-width: $lg;
    padding: $spacing;
    margin-top: $spacing * 4;

    .tabs {
      border-bottom: 1px solid $color-neutral-variant-90;
      .tab {
        width: $tab-size;
        border-radius: $border-radius * 3.5;
      }
    }

    .table {
      margin-top: $spacing * 4;
      width: 100%;
      max-width: 1200px;
    }

    .comingSoon {
      max-width: 600px;
    }
  }
}

.dialog {
  .container {
    width: 500px;
    border-radius: $spacing * 2.5;
    background-color: $color-surface-1;

    .header {
      padding: $spacing;
    }
    .content {
      padding: $spacing * 3;
      text-align: center;

      .chip {
        color: $color-primary-40;
        background-color: $color-primary-90;
        border-radius: $chip-size * 0.5;
        width: $chip-size;
        height: $chip-size;

        .icon {
          margin: auto;
        }
      }
    }
    .footer {
      padding: $spacing * 3;
    }
  }
}
