@import 'styles/global.scss';

.root {
  width: 100%;

  .container {
    border: 1px solid $color-neutral-variant-50;
    border-radius: $spacing * 3;
    padding: $spacing * 4;
    background-color: $color-surface;
    color: $color-primary-10;

    .estimate {
      .icon {
        color: $color-primary-40;
      }
      color: $color-neutral-variant-30;
    }

    .message {
      padding: $spacing * 3;
      border-radius: $spacing * 3;
      background-color: $color-surface-1;

      .content {
        white-space: pre-wrap;
        max-height: 240px;
        overflow: auto;
      }
    }
  }
}
