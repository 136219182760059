@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    min-width: 400px;
    .content {
      width: 100%;
      padding: $spacing * 3;
      padding-bottom: 0;
      overflow: hidden;

      .fields {
        width: 100%;
        .input {
          width: 100%;
        }
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
