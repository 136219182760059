@import 'styles/global';

.root {
  padding: $spacing * 8 $spacing * 4 0 $spacing * 4;
  width: 100%;
  height: calc(100vh - $appbar-height);
}

.dialog {
  max-width: 500px;
  padding: $spacing * 3;
  padding-bottom: 0;
  text-align: center;
}
