@import 'styles/global.scss';

.root {
  width: 100%;

  .mode {
    > button {
      box-shadow: none !important;
      padding-left: $spacing * 2.5 !important;
      padding-right: $spacing * 2.5 !important;
    }
    .left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-width: 0px;
    }
    .mid {
      border-radius: 0;
    }
    .right {
      border-left-width: 0px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .block {
    min-height: 500px;
  }
}
