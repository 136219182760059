@import 'styles/global';

$height: 40px;

.chip {
  color: $color-neutral-variant-30;
  border-radius: $height * 0.5;
  border: 1px solid $color-neutral-variant-50;
  height: $height;
  padding: 0 $spacing * 2;
  align-items: center;
  text-align: center;
  white-space: nowrap;

  &.link {
    cursor: pointer;
  }

  .closeBtn {
    cursor: pointer;
  }
}
