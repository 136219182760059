@import 'styles/global';

.root {
  height: 100%;

  .tab {
    width: 150px;
    padding: $spacing 0;
    background-color: $color-surface;
    color: $color-primary-90;
    cursor: pointer;

    &.selected {
      color: $color-primary-40;
      box-shadow: 0px 1px 10px 0px rgba(110, 110, 110, 0.25);
    }

    &.first {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &.last {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  .content {
    padding: $spacing * 3;
    background-color: $color-surface;
    border-radius: 18px;
    position: relative;
    color: $color-primary-10;
    white-space: pre-line;

    .metric {
      width: 180px;
    }

    .cover {
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      position: absolute;
      background: linear-gradient(#ffffff00, #ffffff);
      padding: $spacing * 2;
      border-radius: 18px;
    }
  }
}
