@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    .content {
      max-width: 500px;
      padding: $spacing * 3;
      overflow: hidden;

      .highlight {
        color: $color-primary-40;
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
