@import 'styles/global';

$step-chip-size: 64px;
$icon-chip-size: 64px;
$icon-svg-size: 40px;

.root {
  width: 100%;

  .container {
    width: $panel-width;
    padding: $spacing * 3 $spacing * 4;

    &.top {
      border-top-left-radius: $spacing * 3.5;
      border-top-right-radius: $spacing * 3.5;
      background-color: $color-surface-4;
    }

    &.bottom {
      border-bottom-left-radius: $spacing * 3.5;
      border-bottom-right-radius: $spacing * 3.5;
      background-color: $color-surface-1;
    }

    .step {
      width: $step-chip-size;
      height: $step-chip-size;
      border-radius: $step-chip-size * 0.5;
    }

    .title {
      flex: 1;
    }

    .card {
      cursor: pointer;
      padding: $spacing * 7 $spacing * 3;
      border-radius: $spacing * 3.5;
      width: 50%;
      background-color: $color-surface-1;

      &:hover {
        background-color: rgba(250, 253, 255, 0.6);
      }

      &.selected {
        background-color: $color-primary-99;
      }

      .icon {
        width: $icon-chip-size;
        height: $icon-chip-size;
        border-radius: $icon-chip-size * 0.5;
        background-color: $color-primary-90;

        .svg {
          width: $icon-svg-size;
          height: $icon-svg-size;
          color: $color-primary-40;

          &.account {
            transform: translateY(3px);
          }

          &.influencer {
            transform: translate(6px, 6px);
          }
        }
      }
    }
  }
}
