@import 'styles/global';

.background {
  position: absolute;
  width: 100%;
  height: 360px;
  left: 0;
  top: 0;
  border-bottom-left-radius: $spacing * 4.5;
  border-bottom-right-radius: $spacing * 4.5;

  &.dark {
    background-color: $color-primary-10;
  }

  &.hasCategory {
    height: 380px;
  }
}

.root {
  position: absolute;
  height: calc(100% - $spacing * 3);
  width: calc(100% - $spacing * 3);
  top: 12px;
  pointer-events: auto;

  .content {
    box-sizing: border-box;
    height: calc(100% - 100px);
    padding: 0 $spacing * 3;
    overflow: auto;
  }
}
