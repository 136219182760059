@import 'styles/global';

.root {
  padding: 0 $spacing * 4;
  width: 100%;

  .card {
    width: 300px;
    border-radius: $spacing;
    background-color: white;
    border: 1px solid $color-neutral-variant-80;

    .img {
      width: 200px;
      min-height: 200px;
      margin: auto;
    }

    .text {
      padding: $spacing * 2;
      text-align: center;
    }
  }
}
