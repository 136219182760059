@import 'styles/global';

$tab-size: 250px;

.root {
  margin: $spacing * 2;
  padding: $spacing;
  padding-bottom: $spacing * 10;
  width: 100%;

  .link {
    cursor: pointer;
  }

  .form {
    margin-top: $spacing * 4;

    .tabs {
      margin: auto;
      border-bottom: 1px solid $color-neutral-variant-90;
      .tab {
        width: $tab-size;
        border-radius: $border-radius * 3.5;
      }
    }
  }
}

.dialog {
  padding: $spacing * 4;
  padding-bottom: 0;
  .title {
    width: 100%;
    text-align: center;
  }
}
