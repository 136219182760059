@import 'styles/global';

$avatar-btn-size: 56px;
$avatar-btn-size-mobile: 48px;
$avatar-icon-size: 50px;
$avatar-address-size: 80px;
$avatar-username-size: 100px;

.root {
  background-color: $color-surface-3 !important;
  height: $avatar-btn-size !important;
  border-radius: $avatar-btn-size * 0.5 !important;
  gap: 0px;

  @media screen and (max-width: $sm) {
    height: $avatar-btn-size-mobile !important;
    border: none !important;
  }

  .avatar {
    width: $avatar-icon-size !important;
    height: $avatar-icon-size !important;

    @media screen and (max-width: $sm) {
      margin: 0px !important;
      width: $avatar-btn-size-mobile !important;
      height: $avatar-btn-size-mobile !important;
    }
  }

  .info {
    color: $color-neutral-variant-30;
    padding-right: $spacing * 2;
    padding-left: $spacing * 2;

    @media screen and (max-width: $sm) {
      display: none;
    }

    .username {
      max-width: $avatar-username-size;
      word-break: break-all;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .address {
      max-width: $avatar-address-size;
      word-break: break-all;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
