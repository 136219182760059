@import 'styles/global';

.root {
  height: 100%;
  padding: 0 $spacing * 3;

  .header {
    padding: $spacing 0;
    .backBtn {
      cursor: pointer;
    }

    .title {
      color: $color-primary-10;
    }
  }

  .filters {
    overflow: auto;
    height: calc(100% - 130px);
  }
}
