@import 'styles/global.scss';

.logo {
  cursor: pointer;
  height: 100%;
  .img {
    height: 100%;
    > path {
      fill: white;
      color: black;
    }
  }
}
