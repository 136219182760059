@import 'styles/global';

$logo-height: 32px;

.root {
  padding: $spacing * 2;
  padding-left: $spacing * 4;
  width: 100%;
  box-sizing: border-box;

  &.dark {
    color: white;
  }

  .backBtn {
    color: inherit;
  }
}
