@import 'styles/global';

.dialog {
  padding: $spacing * 4;
  padding-bottom: 0;
  .title {
    text-align: center;
  }
  .content {
    width: 100%;
    max-height: calc(100vh - 400px);
    overflow: auto;
  }
}

.actions {
  width: 100%;
}
