@import 'styles/global';

.tableHeader {
  padding: $spacing * 3 $spacing * 3;
  align-items: flex-end;
  background-color: $color-surface-1;
}

.plus {
  color: $color-success-40;
}
