@import "styles/global";

.tooltip {
  padding: $spacing * 2;
  border-radius: $spacing;
  background-color: white;
  max-width: 300px;
  text-wrap: wrap;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);

  .brief {
    padding-left: $spacing * 2.5;
  }
}
