@import 'styles/global.scss';

.root {
  min-height: 500px;

  .filterBtn {
    box-shadow: none !important;
    padding-left: $spacing * 2.5 !important;
    padding-right: $spacing * 2.5 !important;
  }

  .tabs {
    color: $color-neutral-variant-30;
    border-bottom: 0.5px solid $color-neutral-variant-30;
    .tab {
      padding: $spacing;
      cursor: pointer;

      &.selected {
        border-bottom: 2px solid $color-primary-40;
        color: $color-primary-40;
      }

      .progress {
        color: $color-primary-40;
      }
    }
  }

  .table {
    .empty {
      max-width: 500px;
      text-align: center;

      .img {
        width: 120px;
      }
    }
  }
}
