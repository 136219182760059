@import 'styles/global';

.title {
  padding-left: $spacing * 4 !important;
  padding-right: $spacing * 3 !important;
  .highlight {
    color: $color-primary-40;
  }
}

.root {
  color: $color-primary-10;
  padding: 0px $spacing * 4 $spacing * 4 $spacing * 4;

  .tweets {
    max-height: calc(100vh - 240px);
    overflow-y: auto;

    .tweet {
      width: 550px;
    }
  }
}
