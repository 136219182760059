@import 'styles/global.scss';

.root {
  flex-wrap: wrap;
  gap: $spacing;

  max-height: 136px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  .filter {
    color: $color-neutral-variant-30;
    padding: 0 $spacing * 2;
  }

  .clear {
    padding: 0 $spacing * 2;
  }
}
