@import 'styles/global';

.root {
  max-width: 900px;
  border-radius: $spacing * 2.5;
  background-color: white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  .title {
    border-radius: $spacing * 2.5 $spacing * 2.5 0 0;
    padding: $spacing * 3;
    background-color: $color-surface-4;
  }
  .chart {
    width: 500px;
    height: 400px;
    padding: $spacing * 6;

    .refresh {
      cursor: pointer;
    }
  }
}
