@import 'styles/global';

$logo-height: 56px;
$logo-height-mobile: 48px;

.root {
  width: 100vw;
  color: black;
  align-items: center;
  position: relative;

  .header {
    padding: 0px 24px;
    width: 100%;
    height: $appbar-height;
    background-color: $color-neutral-10;

    .logo {
      width: 100%;
      height: $logo-height;

      @media screen and (max-width: $sm) {
        height: $logo-height-mobile;
      }

      margin-left: $spacing * 2;
    }
  }

  .container {
    max-width: 1200px;
    width: 100%;
    padding: $spacing * 6;
    padding-bottom: 0;

    @media screen and (max-width: $sm) {
      padding: $spacing * 6 $spacing * 1;
    }

    .top {
      flex-direction: row;
      gap: $spacing * 3;

      @media screen and (max-width: $sm) {
        flex-direction: column;
      }

      .description {
        max-width: 800px;
      }

      .info {
        align-items: flex-end;

        @media screen and (max-width: $sm) {
          align-items: center;
        }

        .tips {
          padding: $spacing;
          border-radius: $spacing * 0.5;
          background-color: #82f9cb;
          color: #008f4d;
        }

        .btn {
          width: 180px;
          cursor: pointer;
          text-align: center;
          border-bottom: 1px solid #dfe5ec;

          .btnText {
            padding: $spacing * 1.5 0;

            &.selected {
              color: $color-primary-40;
              border-bottom: 3px solid $color-primary-40;
            }
          }
        }
      }
    }
  }
}
