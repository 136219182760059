@import 'styles/global.scss';

.root {
  width: 100%;

  .filters {
    flex-wrap: wrap;
    gap: $spacing * 2;
  }

  .verified {
    color: $color-neutral-variant-30;
  }

  .table {
    min-height: 300px;

    .empty {
      max-width: 500px;
      text-align: center;

      .img {
        width: 120px;
      }
    }
  }
}
