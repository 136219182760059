@import "styles/global";

.dialog {
  padding: $spacing * 4;
  .title {
    text-align: center;

    &.highlight {
      color: $color-primary-40;
    }
  }
  .input {
    width: 100%;
  }
}
