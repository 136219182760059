@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    .content {
      min-width: 500px;
      padding: $spacing * 3;
      padding-bottom: 0;
      overflow: hidden;

      .subtitle {
        color: #49454f;
      }

      .plans {
        padding: $spacing * 3;

        .check {
          width: 12px;
          height: 12px;
          color: $color-success-40;
        }

        .close {
          width: 12px;
          height: 12px;
          color: $color-error-40;
        }

        .highlight {
          color: $color-primary-40;
        }
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
