@import 'styles/global';

.root {
  border-radius: $spacing * 1.5;
  border: 1px solid $color-neutral-variant-80;
  padding: $spacing * 3 0;

  .divider {
    margin-top: $spacing * 3;
  }

  .title {
    color: $color-primary-10;
    padding: 0 $spacing * 3;
    cursor: pointer;

    .value {
      color: $color-primary-40;
    }
  }

  .input {
    padding: 0 $spacing * 3;

    .sliderBar {
      width: 100%;

      .levelText {
        color: $color-primary-70;
        width: 20%;
        text-align: center;
      }
    }
  }
}
