@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    .content {
      min-width: 500px;
      padding: $spacing * 3;
      overflow: hidden;
    }

    .audiences {
      flex-wrap: wrap;
      .audience {
        color: $color-primary-40;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
