@import 'styles/global';

.root {
  width: 100%;
  padding: $spacing * 8;

  .card {
    width: 300px;
    border: 1px solid $color-neutral-variant-70;
    border-radius: $spacing * 1.5;

    .img {
      width: 100%;
      height: 180px;
      color: white;
      border-radius: $spacing * 1.5 $spacing * 1.5 0 0;

      &.darkBlue {
        background-color: $color-primary-10;

        .icon {
          color: white;
        }
      }

      &.lightBlue {
        background-color: $color-primary-90;

        .icon {
          color: $color-primary-10;
        }
      }

      .icon {
        width: 56px;
        height: 56px;
      }
    }

    .content {
      padding: $spacing * 2;
      border-radius: 0 0 $spacing * 1.5 $spacing * 1.5;
      flex: 1 1;

      .description {
        flex: 1 1;
      }
    }
  }
}
