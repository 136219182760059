@import 'styles/global';

.root {
  width: 520px;
  .title {
    padding: $spacing * 2;
  }
  .content {
    text-align: center;
    padding: $spacing * 2;

    .link {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .actions {
    padding: $spacing * 3;
  }
}
