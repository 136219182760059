@import 'styles/global';

$filter-width: 360px;

.root {
  &.horizontal {
    width: 100%;
  }

  &.vertical {
    width: $filter-width;
  }

  .title {
    background-color: $color-surface-4;
    padding: $spacing * 3;

    &.horizontal {
      width: 30%;
      border-top-left-radius: $border-radius * 3.5;
      border-bottom-left-radius: $border-radius * 3.5;
    }

    &.vertical {
      border-top-left-radius: $border-radius * 3.5;
      border-top-right-radius: $border-radius * 3.5;
    }

    .label {
      color: $color-primary-40;
    }

    .select {
      margin-top: $spacing;
    }
  }

  .operands {
    background-color: white;
    padding: $spacing * 3;

    &.horizontal {
      flex: 1 1;
      border-top-right-radius: $border-radius * 3.5;
      border-bottom-right-radius: $border-radius * 3.5;
    }

    &.vertical {
      border-bottom-left-radius: $border-radius * 3.5;
      border-bottom-right-radius: $border-radius * 3.5;
    }

    .built {
      color: $color-neutral-variant-50;
      &.disabled {
        color: $color-disabled;
      }
    }

    .btnDelete {
      width: 16px;
      height: 16px;
      transition: color 0ms;
    }

    .btnAdd {
      width: 145px;
      transition: color 0ms;
    }
  }
}
