@import 'styles/global.scss';

.root {
  width: 100%;
  .container {
    border: 1px solid $color-neutral-variant-50;
    border-radius: $spacing * 3;
    padding: $spacing * 4;
    background-color: $color-surface;
    .input {
      width: 100%;
    }
    .extension {
      .icon {
        color: $color-primary-40;
      }
      background-color: $color-surface-1;
      border-radius: $spacing * 3;
      padding: $spacing * 2;
    }
  }
}
