@import 'styles/global';

$tab-size: 250px;

.root {
  width: 100%;
  padding: $spacing * 8;

  .link {
    cursor: pointer;
  }

  .form {
    margin-top: $spacing * 4;

    .tabs {
      margin: auto;
      border-bottom: 1px solid $color-neutral-variant-90;
      .tab {
        width: $tab-size;
        border-radius: $border-radius * 3.5;
      }
    }
  }
}
