@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .root {
    .content {
      max-width: 500px;
      min-width: 400px;
      padding: $spacing * 3;
      overflow: hidden;

      .detail {
        width: 100%;

        .text {
          max-height: 240px;
          overflow: auto;
        }
      }
    }

    .actions {
      padding: $spacing * 3;
    }
  }
}
