@import 'styles/global';

.root {
  .title {
    padding: $spacing * 2;
  }
  .content {
    text-align: center;
    padding: $spacing * 2;
    max-width: 440px;

    .highlight {
      color: $color-primary-40;
    }
  }
  .actions {
    padding: $spacing * 3;
  }
}
