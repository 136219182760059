@import 'styles/global';

$chip-size: 48px;
$tab-size: 250px;

.root {
  width: 100%;

  .blackArea {
    padding: $spacing * 8;
    border-radius: 0px;

    .breadcrumbs {
      margin: $spacing * 2;

      .link {
        cursor: pointer;
      }
    }

    .container {
      margin: auto;
      padding: $spacing * 2;

      .box {
        width: 300px;
        padding: $spacing * 3;
        border-radius: $border-radius * 2;
        background-color: white;
        color: $color-secondary-40;

        .chip {
          color: $color-primary-40;
          background-color: $color-primary-90;
          border-radius: $chip-size * 0.5;
          width: $chip-size;
          height: $chip-size;

          .icon {
            margin: auto;
          }
        }

        .count {
          color: $color-primary-40;
        }
      }
    }
  }

  .detail {
    min-width: $md;
    padding: $spacing;
    padding-bottom: $spacing * 8;
    margin-top: $spacing * 4;

    .tabs {
      border-bottom: 1px solid $color-neutral-variant-90;
      .tab {
        width: $tab-size;
        border-radius: $border-radius * 3.5;
      }
    }
  }
}
