@import 'styles/global';

.dialog {
  .title {
    padding: $spacing * 0.5 $spacing * 0.5 $spacing * 0.5 $spacing * 2;
  }
  .content {
    width: 500px;
    overflow: hidden;
    padding: $spacing * 3;

    .input {
      width: 100%;

      .select {
        width: 100%;
        padding: 0 $spacing * 2;
      }

      .checkbox {
        padding: 0 $spacing * 4;
      }
    }
  }
  .actions {
    padding: $spacing * 3;
  }
}
