@import 'styles/global.scss';

.root {
  width: 100%;
  .top {
    width: 100%;
    min-height: 160px;
    align-items: center;

    background-image: url(../../../public/images/wallet_selector/background.png);
    background-repeat: no-repeat;
    background-size: cover;

    .content {
      margin: auto;
      padding: $spacing * 4 $spacing * 2;
      width: 100%;
      max-width: $lg;
      color: white;

      @media screen and (max-width: $lg) {
        padding: $spacing * 4 $spacing * 2;
      }
    }
  }

  .bottom {
    max-width: $lg;
    width: 100%;
    margin: calc($spacing * 6) auto;
    padding: $spacing * 2;

    .title {
      justify-content: space-between;
    }

    .table {
      width: 100%;
      max-width: inherit;
    }

    .init {
      padding: $spacing * 6;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      text-align: center;

      .btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.dialog {
  .container {
    width: 520px;
    border-radius: $spacing * 2.5;
    background-color: $color-surface-1;

    .header {
      padding: $spacing;
      .close {
        color: $color-primary-40;
      }
    }
    .content {
      padding: $spacing * 3;
      .audiences {
        flex-wrap: wrap;
      }
    }
    .footer {
      padding: $spacing * 3;
    }
  }
}
