@import 'styles/global';

$chip-size: 48px;
$tab-size: 250px;

.root {
  width: 100%;

  .blackArea {
    min-width: $sm;
    padding: $spacing * 2;
    border-radius: 0px;

    .breadcrumbs {
      margin: $spacing * 2;

      .link {
        cursor: pointer;
      }
    }

    .container {
      padding: $spacing * 2;
      padding-bottom: $spacing * 6;

      .block {
        margin: auto;
        max-width: $lg;
        width: 100%;

        .chip {
          height: 40px;
          border-radius: 20px;
          padding: $spacing;
          background-color: $color-primary-90;
          color: $color-primary-40;

          .svg {
            color: $color-primary-40;

            &.influencer {
              transform: translate(2px, 2px);
            }
          }
        }

        .content {
          .info {
            width: 640px;
            .name {
              width: 100%;
            }
          }
          .actionBtn {
            background-color: $color-secondary-20;
            border-radius: $spacing;
            .icon {
              color: $color-primary-80;
            }
          }
        }

        .createdFrom {
          max-width: 100%;
          box-shadow: none;
          &::before {
            opacity: 0 !important;
          }

          .expandBtn {
            background-color: $color-secondary-20;
            border-radius: $spacing;
            .icon {
              color: $color-primary-80;
            }
          }

          .summary {
            &:hover {
              cursor: default;
            }
            padding: 0;
          }

          .details {
            padding: 0;
            .cohorts {
              flex-wrap: wrap;

              .link {
                color: inherit;
                cursor: pointer;

                &:hover {
                  color: $color-primary-90;
                }
              }
            }
          }
        }
      }
    }
  }

  .accounts {
    min-width: $lg;
    padding: $spacing;

    .tabs {
      margin: auto;
      margin-top: $spacing * 6;
      width: 100%;
      max-width: $lg;
      border-bottom: 1px solid $color-neutral-variant-90;
      .tab {
        width: 50%;
        max-width: unset;
        border-radius: $border-radius * 3.5;
        .step {
          border-radius: 10px;
          width: 20px;
          height: 20px;

          background-color: $color-primary-40;
          color: white;

          &.disabled {
            background-color: $color-neutral-variant-30;
          }
        }
        &.disabled {
          opacity: 0.38;
        }
      }
    }

    .table {
      margin-top: $spacing * 4;
      width: auto;

      &.small {
        min-width: 600px;
        max-width: 1200px;
      }
      &.large {
        min-width: 900px;
        max-width: 1200px;
      }
    }

    .empty {
      width: 546px;
      text-align: center;
    }
  }
}
