@import "styles/global";

.root {
  padding: $spacing * 3;

  .highlight {
    color: $color-primary-40;
  }

  .twitterUser {
    padding: $spacing * 3;
    border-radius: 24px;
    background-color: $color-surface;

    .avatar {
      width: 48px;
      height: 48px;
    }

    .username {
      color: $color-secondary-40;
    }

    &:hover {
      .actions {
        visibility: visible;
      }
    }

    .actions {
      visibility: hidden;

      &.selected {
        visibility: visible;
      }

      .button {
        border: 1px solid $color-neutral-variant-50;
        color: $color-primary-40;
      }
    }
  }
}
