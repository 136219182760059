@import 'styles/global';

.moreButton {
  width: 24px;
  height: 24px;
  .row:hover & {
    opacity: 1;
  }
  opacity: 0;
}

.pointer {
  cursor: pointer;
}
