@import 'styles/global';

.root {
  .content {
    text-align: center;
    margin-bottom: $spacing;

    .title {
      padding: 0 $spacing * 2;

      .info {
        padding: $spacing * 2;
        max-width: 500px;
      }
    }

    .items {
      max-height: calc(100vh - 460px);
      overflow: auto;
      .item {
        &.selected {
          background-color: $color-neutral-80;
        }
        padding: $spacing $spacing * 2;

        .option {
          color: $color-neutral-variant-30;
          .text {
            color: $color-neutral-10;
            word-break: break-all;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .actions {
    padding: $spacing * 2 $spacing * 3;
  }
}
