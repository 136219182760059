@import 'styles/global';

.root {
  .title {
    padding: $spacing * 2;
  }
  .content {
    text-align: center;
    padding: $spacing * 2;
    max-width: 440px;

    .textContainer {
      width: 100%;
      .text {
        word-break: break-word;
      }
    }
  }
  .actions {
    padding: $spacing * 3;
  }
}
