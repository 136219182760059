@import 'styles/global.scss';

.root {
  width: 100%;
  padding: $spacing * 4;

  .tabs {
    width: 100%;
    background-color: $color-surface;
    border-radius: $border-radius * 3.5;

    .tab {
      max-width: unset;
      border-radius: $border-radius * 3.5;
    }
  }
}
