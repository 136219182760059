@import 'styles/global.scss';

.root {
  padding: 0;
  max-width: $lg;
  .collectionInfo {
    color: $color-primary-10;
  }
  .highlight {
    color: $color-primary-40;
    &.error {
      color: $color-error-40;
    }
  }
  .table {
    min-height: 300px;

    .empty {
      max-width: 500px;
      text-align: center;

      .img {
        width: 120px;
      }
    }
  }
}

.dialog {
  padding: $spacing * 4;
  padding-bottom: 0;

  @media screen and (max-width: $sm) {
    padding: 0 $spacing;
  }

  .title {
    text-align: center;

    .highlight {
      color: $color-primary-40;
    }
  }
  .input {
    width: 100%;
  }
}

.highlight {
  color: $color-primary-40;
  &.error {
    color: $color-error-40;
  }
}
