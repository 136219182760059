@import 'styles/global.scss';

$block-container-size: 600px;

.root {
  width: 100%;
  height: calc(100vh - $appbar-height);

  .block {
    max-width: $block-container-size;
    padding: $spacing * 2;
    flex: 1 1;

    .container {
      margin: auto;

      .logo {
        height: 56px;
        margin-bottom: $spacing * 2;
      }

      .note {
        text-align: center;

        .contact {
          cursor: pointer;
        }
      }
    }
  }
}
