@import 'styles/global';

.dialog {
  .progressBar {
    height: 12px;
    width: 100%;
    background-color: $color-surface-1;
    .progress {
      height: 100%;
      background-color: $color-primary-90;
    }
  }
  .title {
    padding: $spacing * 1 $spacing * 2;

    .skip {
      color: $color-neutral-variant-30;
      cursor: pointer;
    }
  }
  .root {
    .content {
      max-width: 500px;
      padding: $spacing * 4 $spacing * 2;
      overflow: hidden;

      .img {
        width: 100%;
      }
    }
  }
}
