@import 'styles/global';

.closeBtn {
  position: absolute;
  top: $spacing;
  right: $spacing;
}

.root {
  width: 100%;
  min-width: 600px;
  padding: $spacing * 6;

  @media screen and (max-width: $sm) {
    padding: $spacing * 6 $spacing * 1;
  }

  .starIcon {
    color: $color-primary-40;
  }

  .outboundIcon {
    width: 16px;
    height: 16px;
    color: $color-neutral-variant-30;
    cursor: pointer;
  }

  .bottom {
    padding: $spacing * 3;
    border-radius: 28px;
    background-color: white;

    .btn {
      width: 140px;
      cursor: pointer;
      text-align: center;
      border-bottom: 1px solid #dfe5ec;

      .btnText {
        padding: $spacing * 1.5 0;

        &.selected {
          color: $color-primary-40;
          border-bottom: 3px solid $color-primary-40;
        }
      }
    }
    .input {
      width: 280px;
    }
    .task {
      max-width: 340px;
    }
    .green {
      color: $color-success-40;
    }
    .blue {
      color: $color-primary-40;
    }
  }
}
