@import 'styles/global';

.root {
  position: fixed !important;
  left: $sidebar-width;
  bottom: $spacing * 2;

  .badge {
    position: absolute;
    top: -16px;
    right: -16px;
    background-color: $color-primary-90;
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
}

.panel {
  padding: $spacing * 4 $spacing * 2;

  .allComplete {
    width: 48px;
    height: 48px;
  }

  .percentage {
    text-wrap: nowrap;
  }

  .progressBar {
    border-radius: 6px;
    height: 12px;
    width: 100%;
    background-color: #ebebeb;
    .progress {
      border-radius: 6px;
      height: 100%;
      background-color: $color-primary-40;
    }
  }

  .tutorial {
    &.disabled {
      opacity: 0.38;
      cursor: not-allowed;
    }
    .check {
      width: 32px;
      height: 32px;
      .svg {
        width: 100%;
        height: 100%;
        color: $color-neutral-variant-30;
      }
    }
    .badge {
      background-color: $color-primary-90;
      width: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }
}
