@import 'styles/global';

$step-chip-size: 64px;
$icon-chip-size: 100px;
$icon-svg-size: 40px;

.root {
  width: 100%;

  .container {
    width: $panel-width;
    padding: $spacing * 3;

    &.top {
      border-top-left-radius: $spacing * 3.5;
      border-top-right-radius: $spacing * 3.5;
      background-color: $color-surface-4;
    }

    &.bottom {
      border-bottom-left-radius: $spacing * 3.5;
      border-bottom-right-radius: $spacing * 3.5;
      background-color: $color-surface-1;
    }

    .step {
      width: $step-chip-size;
      height: $step-chip-size;
      border-radius: $step-chip-size * 0.5;
    }

    .title {
      flex: 1;
    }

    .card {
      padding: $spacing * 7 $spacing * 3;
      border-radius: $spacing * 3.5;
      width: 50%;
      transition: all 200ms ease-in;

      &:hover {
        background-color: $color-surface-5;
        transform: scale(1.03);
      }

      &.selected {
        background-color: $color-primary-95;
        border: 2px solid $color-primary-40;
      }

      .icon {
        width: $icon-chip-size;
        height: $icon-chip-size;
        border-radius: $icon-chip-size * 0.5;
        background-color: $color-primary-70;

        .svg {
          width: $icon-svg-size;
          height: $icon-svg-size;
          color: white;

          &.twitterAds {
            transform: translateY(3px);
          }

          &.customized {
            transform: translateY(3px);
          }
        }
      }
    }
  }
}
