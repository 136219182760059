@import 'styles/global.scss';

.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;

  .main {
    display: flex;
    width: 100%;
    flex: 1;
    margin-top: $appbar-height;
    overflow: auto;
    transition: margin-left 0.2s ease;

    &.hideHeader {
      margin-top: 0;
    }

    &.sideBar {
      margin-left: $sidebar-width;
      width: calc(100% - $sidebar-width);
    }

    &.userSideBar {
      margin-left: $user-sidebar-width;
      width: calc(100% - $user-sidebar-width);
    }
  }
}
